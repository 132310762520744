body {
  margin: 0;
  font-size: 14px;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cbddf3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  cursor: default;
}

/* Fix autofill background color */
input:-webkit-autofill,
input:-webkit-autofill::first-line,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-family: 'Helvetica Neue', 'Roboto', sans-serif !important;
  font-size: 14px;
}

/* HIDE ARROW NAVIGATIONS IN NUMBER SELECT */
/* I didn't find a way how to make this work in JSS therefore it's here */

.hideNumberArrows input::-webkit-outer-spin-button,
.hideNumberArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hideNumberArrows input[type='number'] {
  -moz-appearance: textfield;
}

/* hide img of mini pin in map */
.smap .cluster img {
  display: none;
}
.smap .cluster div {
  border-width: 8px !important;
}
.map-card-container div {
  margin-bottom: 4px;
}
.noprint a img {
  display: none;
}

.primaryMainColor {
  color: #428bca;
}

.MuiAutocomplete-option[data-focus='true'] {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 50px #abcae9;
}
